// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/constants.ts"
);
import.meta.hot.lastModified = "1730048705000";
}
// REMIX HMR END


// APP pages
export const APP_PAGES_URL = {
  INDEX: '/',
  APP: '/app',
  WORKSPACE: { INDEX: '/app/{workspaceId}/page' },
  ENTITY: { INDEX: '/app/{workspaceId}/page/{entityId}' },
  ASSESSMENT: {
    INDEX: '/app/{workspaceId}/page/{entityId}/assessment',
    MATURITY: {
      INDEX: '/app/{workspaceId}/page/{entityId}/assessment/maturity',
      NEW: '/app/{workspaceId}/page/{entityId}/assessment/maturity/new',
      BY_ID:
        '/app/{workspaceId}/page/{entityId}/assessment/maturity/view/{assessmentId}',
    },
    MATERIALITY: {
      INDEX: '/app/{workspaceId}/page/{entityId}/assessment/materiality',
      NEW: '/app/{workspaceId}/page/{entityId}/assessment/materiality/new',
      BY_ID:
        '/app/{workspaceId}/page/{entityId}/assessment/materiality/view/{assessmentId}',
      REPORT_BY_ID:
        '/app/{workspaceId}/page/{entityId}/assessment/materiality/report/{assessmentId}',
    },
    CALCULATIONS: {
      INDEX:
        '/app/{workspaceId}/page/{entityId}/assessment/emission-calculations',
      NEW: '/app/{workspaceId}/page/{entityId}/assessment/emission-calculations/new',
      RUN_REPORT:
        '/app/{workspaceId}/page/{entityId}/assessment/emission-calculations/run-report',
    },
    CALCULATIONS_TAB: {
      INDEX:
        '/app/{workspaceId}/page/{entityId}/assessment/emission-calculations-tab',
      SCOPE1:
        '/app/{workspaceId}/page/{entityId}/assessment/emission-calculations-tab/scope1',
      SCOPE2:
        '/app/{workspaceId}/page/{entityId}/assessment/emission-calculations-tab/scope2',
      SCOPE3:
        '/app/{workspaceId}/page/{entityId}/assessment/emission-calculations-tab/scope3',
    },
    STEWARDSHIP_ACTIVITY: {
      INDEX:
        '/app/{workspaceId}/page/{entityId}/assessment/stewardship-activity',
      BY_ID:
        '/app/{workspaceId}/page/{entityId}/assessment/stewardship-activity/view/{stewardshipId}',
    },
    CARBON_FOOTPRINT_INV: {
      INDEX:
        '/app/{workspaceId}/page/{entityId}/assessment/carbon-footprint-inv',
    },
  },
  VALUE_CHAIN: {
    INDEX: '/app/{workspaceId}/page/{entityId}/value-chain',
    MATERIALITY: {
      INDEX: '/app/{workspaceId}/page/{entityId}/manage/configuration/materiality'
    },
    DATA: {
      INDEX: '/app/{workspaceId}/page/{entityId}/value-chain/data',
    },
  },
  REPORT: {
    INDEX: '/app/{workspaceId}/page/{entityId}/reports',
    ESG_REPORTS: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/esg/',
      EMISSION_SHIPPING:
        '/app/{workspaceId}/page/{entityId}/reports/esg/emission-shipping',
      EMISSION_MANUFACTURING:
        '/app/{workspaceId}/page/{entityId}/reports/esg/emission-manufacturing',
      EMISSION_SERVICE:
        '/app/{workspaceId}/page/{entityId}/reports/esg/emission-service',
      EMISSION_CALCULATIONS_SCOPE1:
        '/app/{workspaceId}/page/{entityId}/reports/esg/emission-calculations-scope1',
      EMISSION_CALCULATIONS_SCOPE2:
        '/app/{workspaceId}/page/{entityId}/reports/esg/emission-calculations-scope2',
      EMISSION_CALCULATIONS_SCOPE3:
        '/app/{workspaceId}/page/{entityId}/reports/esg/emission-calculations-scope3',
      EMISSION_CALCULATIONS_WASTE:
        '/app/{workspaceId}/page/{entityId}/reports/esg/emission-calculations-waste',
      EMISSION_ALLSCOPE:
        '/app/{workspaceId}/page/{entityId}/reports/esg/emission-allscope',
      GHG_EMISSION_TRENDS:
        '/app/{workspaceId}/page/{entityId}/reports/esg/ghg-emission-trends',
      GENDER_AND_REMUNERATION:
        '/app/{workspaceId}/page/{entityId}/reports/esg/gender-and-remuneration',
      GENDER_AND_REMUNERATION_2:
        '/app/{workspaceId}/page/{entityId}/reports/esg/gender-and-remuneration-2',
      TRAINING_ACTIVITY:
        '/app/{workspaceId}/page/{entityId}/reports/esg/training-activity',
      FINANCIAL_HIGHLIGHTS:
        '/app/{workspaceId}/page/{entityId}/reports/esg/financial-highlights',
      REAL_ESTATE: '/app/{workspaceId}/page/{entityId}/reports/esg/real-estate',
      INTERNAL_REPORTS:
        '/app/{workspaceId}/page/{entityId}/reports/esg/internal-reports',
      INTERNAL_REPORT_2:
        '/app/{workspaceId}/page/{entityId}/reports/esg/internal-reports2',
      VIEW_BY_ID: {
        EMISSION_SHIPPING:
          '/app/{workspaceId}/page/{entityId}/reports/esg/emission-shipping/view/{reportSlug}',
        EMISSION_MANUFACTURING:
          '/app/{workspaceId}/page/{entityId}/reports/esg/emission-manufacturing/view/{reportSlug}',
        EMISSION_SERVICE:
          '/app/{workspaceId}/page/{entityId}/reports/esg/emission-service/view/{reportSlug}',
        GHG_EMISSION_TRENDS:
          '/app/{workspaceId}/page/{entityId}/reports/esg/ghg-emission-trends/view/{reportSlug}',
        GENDER_AND_REMUNERATION:
          '/app/{workspaceId}/page/{entityId}/reports/esg/gender-and-remuneration/view/{reportSlug}',
        GENDER_AND_REMUNERATION_2:
          '/app/{workspaceId}/page/{entityId}/reports/esg/gender-and-remuneration-2/view/{reportSlug}',
        TRAINING_ACTIVITY:
          '/app/{workspaceId}/page/{entityId}/reports/esg/training-activity/view/{reportSlug}',
        FINANCIAL_HIGHLIGHTS:
          '/app/{workspaceId}/page/{entityId}/reports/esg/financial-highlights/view/{reportSlug}',
        EMISSION_CALCULATIONS_SCOPE1:
          '/app/{workspaceId}/page/{entityId}/reports/esg/emission-calculations-scope1/view/{reportSlug}',
        EMISSION_CALCULATIONS_SCOPE2:
          '/app/{workspaceId}/page/{entityId}/reports/esg/emission-calculations-scope2/view/{reportSlug}',
        EMISSION_CALCULATIONS_SCOPE3:
          '/app/{workspaceId}/page/{entityId}/reports/esg/emission-calculations-scope3/view/{reportSlug}',
        EMISSION_ALLSCOPE:
          '/app/{workspaceId}/page/{entityId}/reports/esg/emission-allscope/view/{reportSlug}',
        EMISSION_CALCULATIONS_WASTE:
          '/app/{workspaceId}/page/{entityId}/reports/esg/emission-calculations-waste/view/{reportSlug}',
        REAL_ESTATE:
          '/app/{workspaceId}/page/{entityId}/reports/esg/real-estate/view/{reportSlug}',
      },
    },
    BANKING_REPORTS: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/banking/',
      SASB_INDEX: '/app/{workspaceId}/page/{entityId}/reports/banking/sasb',
      GRI_INDEX: '/app/{workspaceId}/page/{entityId}/reports/banking/gri',
      BRSR: '/app/{workspaceId}/page/{entityId}/reports/banking/brsr',
      SCOPE_ANALYSIS:
        '/app/{workspaceId}/page/{entityId}/reports/banking/scope-analysis',
      EMISSION_TRENDS:
        '/app/{workspaceId}/page/{entityId}/reports/banking/emission-trends',
      GENDER_AND_REMUNERATION:
        '/app/{workspaceId}/page/{entityId}/reports/banking/gender-and-remuneration',
      FINANCIAL_HIGHLIGHTS:
        '/app/{workspaceId}/page/{entityId}/reports/banking/financial-highlights',
    },
    KPI_REPORTS: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/kpi/',
      KPI_DEFINITIONS:
        '/app/{workspaceId}/page/{entityId}/reports/kpi/kpi-report',
      VIEW_BY_ID:
        '/app/{workspaceId}/page/{entityId}/reports/kpi/kpi-report/view/{reportSlug}',
    },
    BRSR: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/brsr/',
    },
    TARGET_TRACKING: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/target-tracking',
      BY_ID:
        '/app/{workspaceId}/page/{entityId}/reports/target-tracking/view/{targetId}',
    },
    STEWARDSHIP_ACTIVITY: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/stewardship-activity',
      VIEW_BY_ID:
        '/app/{workspaceId}/page/{entityId}/reports/stewardship-activity/view/{reportSlug}',
    },
    CARBON_FOOTPRINTS_INV: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/carbon-footprint-inv',
      VIEW_BY_ID:
        '/app/{workspaceId}/page/{entityId}/reports/carbon-footprint-inv/view/{reportSlug}',
    },
    CARBON_FOOTPRINT: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/carbon-footprint',
    },
    DYNAMIC_REPORTS: {
      INDEX: '/app/{workspaceId}/page/{entityId}/reports/dynamic-reports/',
      VIEW_BY_ID:
        '/app/{workspaceId}/page/{entityId}/reports/dynamic-reports/view/{reportSlug}',
      PREVIEW_BY_ID:
        '/app/{workspaceId}/page/{entityId}/reports/dynamic-reports/preview/{reportSlug}',
    },
    BY_SLUG: '/app/{workspaceId}/page/{entityId}/reports/view',
  },
  DATA_UPLOAD: {
    INDEX: '/app/{workspaceId}/page/{entityId}/data-upload/etl',
    EMISSION_SHIPPING:
      '/app/{workspaceId}/page/{entityId}/data-upload/etl/emission-shipping',
    EMISSION_MANUFACTURING:
      '/app/{workspaceId}/page/{entityId}/data-upload/etl/emission-manufacturing',
    ESG_REPORTS:
      '/app/{workspaceId}/page/{entityId}/data-upload/etl/esg-reports',
    BRSR_REPORTS:
      '/app/{workspaceId}/page/{entityId}/data-upload/etl/brsr-reports',
    BRSR_REPORTS_VIEW:
      '/app/{workspaceId}/page/{entityId}/data-upload/etl/view/{brsrId}',
    REAL_ESTATE:
      '/app/{workspaceId}/page/{entityId}/data-upload/etl/real-estate',
    EMISSION_CALCULATIONS: {
      INDEX:
        '/app/{workspaceId}/page/{entityId}/data-upload/etl/emission-calculations/upload',
      NEW: '/app/{workspaceId}/page/{entityId}/data-upload/etl/emission-calculations/new',
    },
  },
  SETTINGS: {
    INDEX: '/app/{workspaceId}/page/manage',
    USER_MANAGEMENT: {
      INDEX: '/app/{workspaceId}/page/manage/users',
      USER_LIST: '/app/{workspaceId}/page/manage/users/list',
      USER_ROLES: '/app/{workspaceId}/page/manage/users/roles',
    },
    ORGANIZATION_MANAGEMENT: {
      INDEX: '/app/{workspaceId}/page/manage/entity',
      ORGANIZATION_PROFILE: '/app/{workspaceId}/manage/organization-profile',
    },
    CONFIGURATIONS: {
      INDEX: '/app/{workspaceId}/page/{entityId}/manage/configuration',
      MATURITY: {
        INDEX:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/maturity',
        SCORE_TABLE:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/maturity/score-table',
        LEVEL_TABLE:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/maturity/level-table',
        COMPLETED:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/maturity/completed',
      },
      MATERIALITY: {
        INDEX:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/materiality',
        STAKEHOLDER:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/materiality/stakeholder-and-weightage',
        RESPONDERS:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/materiality/responders',
        SURVEYQUESTIONS:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/materiality/survey-questions',
        IMPACTCATEGORY:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/materiality/impact-category',
        COMPLETED:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/materiality/completed',
      },
      KPI_DEFINITIONS: {
        INDEX:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/kpi-definitions',
      },
      DYNAMIC_REPORTS: {
        INDEX:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/dynmaic-reports',
      },
      TRACKING_DEFINITIONS: {
        INDEX:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/tracking-definitions',
      },
      STEWARDSHIP_MASTER: {
        INDEX:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/stewardship-master',
      },
      EMISSION: {
        INDEX:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/emission',
        UNIT_CONVERSION:
          '/app/{workspaceId}/page/{entityId}/manage/configuration/emission/unit-conversion',
        CONVERSION_FACTOR: {
          EPA_CONVERSION_FACTOR:
            '/app/{workspaceId}/page/{entityId}/manage/configuration/emission/conversion-factor/epa-conversion-factor',
          IMO: '/app/{workspaceId}/page/{entityId}/manage/configuration/emission/conversion-factor/imo',
          GWP: '/app/{workspaceId}/page/{entityId}/manage/configuration/emission/conversion-factor/gwp',
          IATA: '/app/{workspaceId}/page/{entityId}/manage/configuration/emission/conversion-factor/iata',
          SOURCE:
            '/app/{workspaceId}/page/{entityId}/manage/configuration/emission/conversion-factor/ipcc',
        },
      },
    },
    ENTITY_MANAGEMENT: {
      INDEX: '/app/{workspaceId}/page/manage/entity',
      ENTITY_SETTINGS:
        '/app/{workspaceId}/page/manage/settings/entity/{entityId}',
      ENTITY_DEPARTMENT:
        '/app/{workspaceId}/page/manage/settings/entity/{entityId}/department',
    },
    AUDIT_LOG: {
      INDEX: '/app/{workspaceId}/page/{entityId}/manage/auditlog',
      AUDIT_CONFIGURATION:
        '/app/{workspaceId}/page/{entityId}/manage/configuration/auditlog',
      AUDIT_DATA_UPLOAD:
        '/app/{workspaceId}/page/{entityId}/manage/dataupload/auditlog',
      AUDIT_REPORT: '/app/{workspaceId}/page/{entityId}/manage/report/auditlog',
      AUDIT_EMISSION_CALCULATION:
        '/app/{workspaceId}/page/{entityId}/manage/emission-calculations/auditlog',
    },
    // ORGANIZATION_PROFILE: {
    //   INDEX: '/app/{workspaceId}/manage/organization-profile',
    // },
    DASHBOARD_CONFIGURATION: {
      INDEX: '/app/{workspaceId}/manage/dashboard-configuration',
    },
    APPLICATION_TOKEN: {
      INDEX: '/app/{workspaceId}/page/manage/application-token',
    },
  },
  WORKFLOWS: {
    MATURITY: '/app/{workspaceId}/page/{entityId}/workflows/maturity',
    MATERIALITY: '/app/{workspaceId}/page/{entityId}/workflows/materiality',
    KPI: '/app/{workspaceId}/page/{entityId}/workflows/kpi',
    REPORT: '/app/{workspaceId}/page/{entityId}/workflows/report',
  },
};

// Authentication pages
export const AUTH_PAGES_URL = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  FORGOTPASSWORD: '/auth/forgot-password',
  RESETPASSWORD: '/auth/reset-password',
  VERIFY: '/auth/verify',
  LOGOUT: '/auth/logout',
  APP: '/app',
};

export const ADMIN_PAGES_URL = {
  LOGIN: '/admin/auth/login',
  FORGOTPASSWORD: '/admin/auth/forgot-password',
  RESETPASSWORD: '/admin/auth/reset-password',
  ADMIN: '/admin',
  USER_MANAGEMENT: {
    INDEX: '/admin/manage/user',
    USER_LIST: '/admin/manage/user/list',
    USER_ROLES: '/admin/manage/user/roles',
    USER_PERMISSIONS: '/admin/manage/user/permissions',
  },
  WORKSPACE_MANAGEMENT: {
    INDEX: '/admin/manage/workspaces/',
    WORKSPACES_LIST: '/admin/manage/workspaces/list',
    WORKSPACES_MODULES: '/admin/manage/workspaces/modules',
    WORKSPACES_MODULES_PERMISSIONS:
      '/admin/manage/workspaces/modules/permissions',
  },
  WORKSPACES_MANAGEMENT_SETTINGS: {
    INDEX: '/admin/{workspaceId}/manage/settings/',
    WORKSPACES_SETTINGS: '/admin/{workspaceId}/manage/settings/entity',
    WORKSPACES_DEPARTMENT: '/admin/{entity}/manage/entity/settings/department',
  },
  CONFIGURATION_MANAGEMENT: {
    INDEX: '/admin/manage/configuration/',
    CONFIGURATION_LIST: '/admin/manage/configuration/emission-standard',
    // CONFIGURATION_MATURITY: '/admin / manage / maturity / configuration / category',
  },
  CONFIGURATION_MANAGEMENTS: {
    INDEX: '/admin/manage/maturity/configuration/category',
    // CONFIGURATION_MATURITY: '/admin/manage/maturity/configuration/category',
  },
  KPI_LIBRARY: {
    INDEX: '/admin/manage/kpi/configuration/kpi-library',
  },
  TEAMPLATE_MANAGEMENT: {
    DYNAMIC_REPORT_LIST: '/admin/manage/templates/dynamic-reports/list',
    DYNAMIC_REPORT_EDIT:
      '/admin/manage/templates/dynamic-reports/edit/{templateId}',
  },
  CONFIGURATIONS: {
    MATURITY: {
      INDEX: '/admin/configuration/maturity',
      SCORE_TABLE: '/admin/configuration/maturity/score-table',
      LEVEL_TABLE: '/admin/configuration/maturity/level-table',
    },
    MATERIALITY: {
      INDEX: '/admin/configuration/materiality',
      STAKEHOLDER: '/admin/configuration/materiality/stakeholder-and-weightage',
      RESPONDERS: '/admin/configuration/materiality/responders',
      SURVEYQUESTIONS: '/admin/configuration/materiality/survey-questions',
      IMPACTCATEGORY: '/admin/configuration/materiality/impact-category',
    },
  },
};

export const CONTENT_PAGES = {
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  CONTACT_US: '/contact-us',
  ABOUT_US: '/about-us',
  USER_GUIDE: '/help-center/user-guide',
  KNOWLEDGE_ASSET: {
    INDEX: '/app/{workspaceId}/page/{entityId}/knowledge-assets',
    REGULATIONS: {
      INDEX: '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations',
      INTERNATIONAL:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/international',
      PATHWAY:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/pathway-to-net-zero',
      SBTVALUE:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/SBT-Value-Chain-Report-1',
      SBTICORPORATE:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/SBTi-Corporate-Manual',
      GLEC_FRAMEWORK:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/GLEC-Framework',
      GHG_PROTOCOL:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/GHG-Protocol',
      MARINE_TRANSPORTATION:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/Marine-Transportation-Standard',
      REGIONAL_NATIONAL:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/regional-national',
      BRSR: '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/brsr',
      OMAN: '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/Oman-ESG-MSX-E',
      QATAR_GUIDANCE:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/Qatar-Guidance-ESG-Reporting',
      UAE_CBUAE_EN:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/UAE-CBUAE-EN',
      UAE_ADX:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/UAE-ADX-Disclosure-requirements',
      UAE_GUIDE:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/UAE-dfm-guide-esg-reporting',
      RETAIL_ESG_FUNDS:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/Retail-ESG-Funds',
      ESG_METRICS_WEBSITE:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/ESG-Metrics-website',
      WHITEPAPERS:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/Insurance-SAM-ESG-Whitepaper',
      LOGISTICRISK_SUSTAINABILITY:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/Logistic-Risks-and-Sustainability',
      ESGWHITEPAPER:
        '/app/{workspaceId}/page/{entityId}/knowledge-assets/regulations/SAM-ESG-Whitepaper',
    },
    WHITE_PAPERS: {
      INDEX: '/app/{workspaceId}/page/{entityId}/knowledge-assets/white-papers',
    },
    CALCULATOR: {
      INDEX: '/app/{workspaceId}/page/{entityId}/knowledge-assets/calculator',
    },
  },
};

export enum SYSTEM_USER_TYPES {
  BE_USER = 'BE_USER',
  FE_USER = 'FE_USER',
}

export enum EMISSION_CONFIGURATION {
  STANDARD = 'emission-standard',
  CRITERIATYPE = 'emission-criteria',
  ACTIVITY_UNITS = 'emission-activity-units',
  CATEGORY = 'emission-category',
  SUB_CATEGORY = 'emission-sub-category',
  VALUE = 'emission-values',
  // SCOPE_CONFIGURATION = 'scope-configuration',
}
export enum MATURITY_CONFIGURATION {
  CATEGORY = 'category',
  SUB_CATEGORY = 'subCategory',
  QUESTIONS = 'questions',
}
export enum WORKSPACES_SETTINGS {
  ENTITY = 'entity',
  MODULE = 'module',
  DYNAMIC_REPORT = 'dynamic-report',
  EMISSION = 'emission',
}
export enum BACKEND_USER_ROLES {
  MASTER = 'BE_MASTER',
  ADMIN = 'BE_ADMIN',
  USER = 'BE_USER',
}

export enum WEB_USER_ROLES {
  OWNER = 'WEB_OWNER',
  ADMIN = 'WEB_ADMIN',
  USER = 'WEB_USER',
}

export enum ASSESSMENT_STATUS {
  Submitted = 'Submitted',
  InProgress = 'In Progress',
}

export enum DASHBOARD_DEFAULT_TAB {
  SHIPPING = 'Shipping',
  MANUFACTURING = 'Manufacturing',
  SERVICE = 'Service',
  GHG_EMISSION = 'GHG Emission',
  GENDER_AND_REMUNERATION = 'Gender And Remuneration',
  FINANCE = 'Finance',
  REAL_ESTATE = 'Real Estate',
  PORTFOLIO_CARBON_FOOTPRINT = 'Portfolio Carbon Footprint',
  MATERIALITY = 'Materiality',
  MATURITY = 'Maturity',
}
export enum DASHBOARD_DEFAULT_TAB_STATUS {
  Active = 'Active',
  Inactive = 'Inactive',
}
export enum SURVEY_STATUS {
  Pending = 'Pending',
  Sent = 'Survey Sent',
  Submitted = 'Survey Submitted',
  Completed = 'Survey Completed',
  Closed = 'Survey Closed',
}
export enum ENTITY_SETTINGS {
  DEPARTMENT = 'department',
  FACILITY = 'facility',
}

export enum SURVEY_EMAIL_STATUS {
  Failed = 'Failed',
  Pending = 'Pending',
  Sent = 'Sent',
  Resend = 'Resend',
}

export enum WORKSPACE_INVITATION_STAUS {
  Cancelled = 'Cancelled',
  Invited = 'Invited',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export enum WORKSPACE_SUBSCRIPTION_STAUS {
  Cancelled = 'Cancelled',
  Inactive = 'Inactive',
  Active = 'Active',
  Deleted = 'Deleted',
  Expired = 'Expired',
}
export enum WORKSPACE__TYPE {
  Cloud1 = 'Cloud1',
  Cloud2 = 'Cloud2',
  Cloud3 = 'Cloud3',
}
export enum MODAL__TYPE {
  Create = 'create',
  Update = 'update',
}

export enum REPORT_CONFIG_FILE_TYPE {
  ESG = 'ESG',
  EMISSION_SHIPPING = 'EMISSION SHIPPING',
  EMISSION_MANUFACTURING = 'EMISSION MANUFACTURING',
  BRSR = 'BRSR',
}

export enum REPORTS_TYPE {
  EMISSION_SHIPPING = 'EMISSION SHIPPING',
  EMISSION_MANUFACTURING = 'EMISSION MANUFACTURING',
  EMISSION_SERVICE = 'EMISSION SERVICE',
  EMISSION_CALCULATIONS = 'EMISSION CALCULATIONS',
  EMISSION_CALCULATIONS_SCOPE2 = 'EMISSION CALCULATIONS SCOPE2',
  EMISSION_CALCULATIONS_SCOPE3 = 'EMISSION CALCULATIONS SCOPE3',
  GHG_EMISSION_TRENDS = 'GHG EMISSION TRENDS',
  GENDER_AND_REMUNERATION = 'GENDER AND REMUNERATION',
  FINANCIAL_HIGHLIGHTS = 'FINANCIAL HIGHLIGHTS',
  KPI_DEFINITIONS = 'KPI DEFINITIONS',
  REAL_ESTATE = 'REAL_ESTATE',
  DYNAMIC_REPORTS = 'DYNAMIC REPORTS',
  TRAINING_ACTIVITY = 'TRAINING ACTIVITY',
  MATURITY_ASSESSMENTS = 'MATURITY ASSESSMENTS',
  MATERIALITY_ASSESSMENTS = 'MATERIALITY ASSESSMENTS',
  STEWARDSHIP_ACTIVITY = 'STEWARDSHIP ACTIVITY',
  CARBON_FOOTPRINT_INV = 'CARBON FOOTPRINT INV',
  GENDER_AND_REMUNERATION_2 = 'GENDER AND REMUNERATION 2',
  EMISSION_ALLSCOPE = 'EMISSION ALLSCOPE',
  EMISSION_CALCULATIONS_WASTE = 'EMISSION CALCULATIONS WASTE',
}

export enum ENTITY_TYPE {
  OrganizationProfile = 'OrganizationProfile',
  Entity = 'Entity',
  Workspace = 'Workspace',
}

export enum AUDIT_LOG_ACTIONS {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Upload = 'Upload',
}

export const AUDIT_LOG_ENTITY_TYPE = {
  Workspace: {
    Maturity: {
      Configuration: {
        ScoreTable: 'Workspace.Configuration.Maturity.ScoreTable',
        LevelTable: 'Workspace.Configuration.Maturity.LevelTable',
      },
    },
    Materiality: {
      Configuration: {
        Stakeholder: 'Workspace.Configuration.Materiality.Stakeholder',
        Responders: 'Workspace.Configuration.Materiality.Responders',
        SurveyQuestions: 'Workspace.Configuration.Materiality.SurveyQuestions',
        ImpactCategories:
          'Workspace.Configuration.Materiality.ImpactCategories',
      },
    },
    KpiDefinitions: {
      Configuration: {
        KpiDefinition: 'Workspace.Configuration.KpiDefenitions.KpiDefenition',
        KpiDefinitionLibrary:
          'Workspace.Configuration.KpiDefenitions.KpiLibrary',
      },
    },
    Emissioncalculations: {
      Assessment: {
        Emissioncalculation:
          'Workspace.Assessment.Emissioncalculations.Emissioncalculation',
        Emissiondataupload:
          'Workspace.Assessment.Emissioncalculations.Emissiondataupload',
      },
    },
    Dataupload: {
      Shipping: 'Workspace.Dataupload.Shipping',
      Manufacturing: 'Workspace.Dataupload.Manufacturing',
      EsgReport: 'Workspace.Dataupload.EsgReport',
      ActivityData: 'Workspace.Dataupload.ActivityData',
    },
    EsgReport: {
      Reports: {
        Shipping: 'Workspace.Reports.EsgReport.Shipping',
        Manufacturing: 'Workspace.Reports.EsgReport.Manufacturing',
        Service: 'Workspace.Reports.EsgReport.Service',
        GHGEmissionTrends: 'Workspace.Reports.EsgReport.GHGEmissionTrends',
        GenderAndRemuneration:
          'Workspace.Reports.EsgReport.GenderAndRemuneration',
        FinancialHighlights: 'Workspace.Reports.EsgReport.FinancialHighlights',
      },
    },
    DisclosureReport: {
      Reports: {
        Manufacturing: 'Workspace.Reports.DisclosureReport.ScopeAnalysis',
        EmissionTrends: 'Workspace.Reports.DisclosureReport.EmissionTrends',
        GenderAndRemuneration:
          'Workspace.Reports.DisclosureReport.GenderAndRemuneration',
        FinancialHighlights:
          'Workspace.Reports.DisclosureReport.FinancialHighlights',
      },
    },
  },
};

export enum REPORT_TEMPLATE_TYPE {
  DYNAMIC_REPORT_TEMPLATE = 'Dynamic Report Template',
}
export const TARGET_ACTIONS_STATUSES = {
  TODO: 'Yet to Start',
  IN_PROGRESS: 'In Progress',
  HOLD: 'Hold',
  COMPLETED: 'Completed',
};

export enum ACTIVITY_UNIT_TYPE {
  Weight = 'Weight',
  Distance = 'Distance',
  Electrical = 'electrical',
}

export enum TRAVEL_TYPE {
  Air = 'Air',
  Ocean = 'Ocean',
}
